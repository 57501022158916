* {
    background-color: #262626;
}

.App {
    text-align: center;
    background: #262626;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    width:100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.header-generic {
    font-family: Open Sans, serif;
    font-style: normal;
    font-weight: normal;
    color: #FFFFFF;
}

.header-instruction {
    font-size: 14px;
    line-height: 21px;
    float: left;
    padding-bottom: 9px;
}

.input-wrapper {
    height: 40px;
    position:relative;
    padding:0;
    margin:0;
}

.search-container {
    min-width: 312px;
    width: 15%;
    margin: 0 auto;
    padding-top: 50px;
}

.system-search {
    height:40px;
    width: 100%;
    padding-left: 30px;
    border: 2px solid #CDCDCD;
    box-sizing: border-box;
    border-radius: 2px;
    color: #8C8C8C;

    background-image: url("images/search.png");
    background-position: 7px 8px;
    background-size: 18px 18px;
    background-repeat: no-repeat;
}

.dropdown {
    padding-top: 60px;
}

.dropdown-item {
    height: 38px;
    padding-top: 14px;
    border-radius: 2px;
    color: white;
}

.results-text {
    color: white;
    float: left;
    padding-top:36px;
}

.results-container {
    min-width: 312px;
    width: 15%;
    margin:0 auto;
}

.logo {
    padding-top: 100px;
    min-width: 312px;
    width: 15%;
    margin:0 auto;
}